import { isExternalLink, resolveWebLink } from '@/storyblok/utils/link';
import { LinkStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import Link from 'next/link';
import { StoryblokStory } from 'storyblok-generate-ts';

export const FooterLink = ({ link: component, story }: { link: LinkStoryblok; story: StoryblokStory<StoryblokComponent> }) => {
  const { link, title } = component;
  const LinkEl = link && isExternalLink(link) ? 'a' : Link;

  if (!link) return null;

  return (
    <LinkEl
      role="menuitem"
      href={resolveWebLink(link, { additional: { name: 'footer section menu item', link }, story, component })}
      className="focus:text-text-body hover:text-text-body transition-colors text-sm"
    >
      {title}
    </LinkEl>
  );
};
