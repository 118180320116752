import { MobileHeaderNav } from './header/mobile';
import { DesktopHeaderNav } from './header/desktop';
import { HeaderBanner } from './header/banner';
import { StoryblokReactComponent } from '@/storyblok/components/componentsMap';
import { HeaderStoryblok } from '@/types/types-storyblok';
import { HeaderContainer } from './header/container';

const HeaderComponent: StoryblokReactComponent<HeaderStoryblok> = ({ component: header, story }) => {
  return (
    <>
      <HeaderContainer logo={header.logo}>
        <MobileHeaderNav linkGroups={header.sections ?? []} component={header} story={story} />
        <DesktopHeaderNav linkGroups={header.sections ?? []} component={header} story={story} />
      </HeaderContainer>
      {header?.bannerEnabled && header?.bannerContent && <HeaderBanner story={story} bannerContent={header.bannerContent} />}
    </>
  );
};

export default HeaderComponent;
