import clsx from 'clsx';
import { Plus_Jakarta_Sans } from 'next/font/google';
import { Header } from '@/app/_components/header';
import { Footer } from '@/app/_components/footer';
import { PropsWithChildren } from 'react';

const plusJakartaSans = Plus_Jakarta_Sans({ subsets: ['latin'], weight: 'variable' });

export const PageContainer = ({
  hideHeader,
  headerSlug,
  hideFooter,
  footerSlug,
  children,
}: PropsWithChildren<{
  hideHeader?: boolean;
  headerSlug?: string;
  footerSlug?: string;
  hideFooter?: boolean;
}>) => {
  return (
    <div className={clsx(plusJakartaSans.className, 'bg-body-background')}>
      {!hideHeader && <Header slug={headerSlug ?? 'layout/header'} />}
      <main>{children}</main>
      {!hideFooter && <Footer slug={footerSlug ?? 'layout/footer'} />}
    </div>
  );
};
