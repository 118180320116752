import Image from 'next/image';
import { render } from 'storyblok-rich-text-react-renderer';
import React from 'react';
import { StoryblokReactComponent } from '@/storyblok/components/componentsMap';
import { FooterStoryblok } from '@/types/types-storyblok';
import { resolveWebLink } from '@/storyblok/utils/link';
import Link from 'next/link';
import { FooterLink } from './footer/footerLink';
import { DAVINCI_CDN } from '@/routes';
import { makeRichTextRenderOptions } from '@/storyblok/utils/richText';

const FooterComponent: StoryblokReactComponent<FooterStoryblok> = async ({ component: footer, story }) => {
  return (
    <footer className="flex flex-col py-12 px-4 gap-10 lg:px-0 md:grid xl:max-w-screen-xl xl:mx-auto">
      <div className="flex flex-col gap-5 md:col-start-1 md:row-start-1 lg:pl-20">
        <Image src={`${DAVINCI_CDN}/elty/elty-logo.svg`} height={33} width={108} alt="Logo" />
        <div className="text-2xl lg:hidden">{render(footer.slogan, makeRichTextRenderOptions(story))}</div>
      </div>

      <div className="text-2xl hidden lg:block col-start-1 row-start-3 lg:pl-20">
        {render(footer.slogan, makeRichTextRenderOptions(story))}
      </div>

      <div className="flex flex-col gap-4 lg:flex-grow lg:flex-row lg:items-center lg:justify-end lg:gap-9 md:col-start-1 md:row-start-2 lg:col-start-2 lg:row-start-1">
        <div className="font-semibold w-1/2 lg:w-auto">{render(footer.appDownloadCta, makeRichTextRenderOptions(story))}</div>
        <div className="flex flex-row gap-1.5">
          <Link href={footer.appIosUrl?.url} target="_blank" rel="noopener noreferrer" className="h-7">
            <Image
              src={`${DAVINCI_CDN}/elty/download-on-app-store.png`}
              height={56}
              width={184}
              alt="Download on App Store"
              className="h-7 w-auto"
            />
          </Link>
          <Link href={footer.appAndroidUrl?.url} target="_blank" rel="noopener noreferrer" className="h-7">
            <Image
              src={`${DAVINCI_CDN}/elty/download-on-google-play.png`}
              height={56}
              width={183}
              alt="Download on Google Play"
              className="h-7 w-auto"
            />
          </Link>
        </div>
      </div>

      <ul className="flex flex-row gap-2 md:col-start-1 md:row-start-3 lg:col-start-4 lg:row-start-1 lg:pr-20">
        {footer.socials?.map(social => (
          <li key={social._uid} title={social.Name}>
            {social.Link && (
              <Link
                href={resolveWebLink(social.Link, { additional: { name: 'footer social', social }, story, component: footer })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.Icon && social.Name && <Image src={social.Icon?.filename} alt={social.Name} width={30} height={30} />}
              </Link>
            )}
          </li>
        ))}
      </ul>

      <hr className="hidden lg:block border-neutral-lighter w-full lg:row-start-2 lg:col-span-4 lg:col-start-1" />

      <nav className="md:col-start-2 md:col-span-2 md:row-start-1 md:row-span-3 lg:row-start-3 lg:col-start-2 lg:col-span-3 lg:row-span-2 lg:pr-20 lg:flex lg:flex-row lg:justify-end ">
        <ul className="flex flex-col gap-8 md:flex-row lg:w-full lg:justify-between lg:max-w-[700px] lg:pl-20 xl:pl-0">
          {footer.sections?.map(section => (
            <li key={section._uid} className="flex flex-col gap-5">
              <h2 className="text-elty-green font-semibold">{section.title}</h2>
              <nav>
                <ul role="menu" className="flex flex-col gap-3 ">
                  {section.links?.map(link => (
                    <li key={link._uid} role="presentation">
                      <FooterLink link={link} story={story} />
                    </li>
                  ))}
                </ul>
              </nav>
            </li>
          ))}
        </ul>
      </nav>

      <div className="flex flex-row items-center lg:col-start-1 lg:row-start-5 lg:pl-20">
        <Image src={`${DAVINCI_CDN}/elty/unipol-group-logo.png`} height={98} width={233} alt="Unipol logo" className="h-9 w-auto" />
      </div>

      <div className="text-text-body text-xs lg:col-start-1 lg:row-start-4 lg:pl-20 [&>p]:leading-[1.125rem]">
        {render(footer.legalText, makeRichTextRenderOptions(story))}
      </div>

      <div className="text-text-body lg:flex flex-row text-xs lg:gap-2 lg:justify-end lg:items-end lg:col-start-2 lg:col-span-3 lg:row-start-5 lg:pr-20">
        <div className="lg:whitespace-nowrap">{footer.bottomText}</div>
        <nav>
          <ul className="flex flex-row items-center flex-wrap" role="menu">
            {footer.legal?.map((link, i) => (
              <React.Fragment key={link._uid}>
                <li role="presentation" className="whitespace-nowrap focus:text-black hover:text-black transition-colors">
                  {link.link && (
                    <Link
                      role="menuitem"
                      href={resolveWebLink(link.link, { additional: { name: 'footer legals link item', link }, story, component: footer })}
                    >
                      {link.title}
                    </Link>
                  )}
                </li>
                {i !== (footer.legal?.length || 0) - 1 && (
                  <li className="px-1" role="separator">
                    |
                  </li>
                )}
              </React.Fragment>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default FooterComponent;
