'use client';
import Image from 'next/image';
import NavLink from '@/app/_components/navLink';
import { clientEnv } from '@/env/client';
import { PageContainer } from '@/app/_components/pageContainer';

const Page = () => {
  return (
    <PageContainer>
      <div className="bg-neutral-surface pt-16 pb-32">
        <div className="grid grid-cols-[1fr] justify-items-center gap-y-10">
          <div className="relative h-56 w-56 md:h-72 md:w-72 lg:h-80 lg:w-80">
            <Image src={`${clientEnv.NEXT_PUBLIC_DAVINCI_ASSETS_CDN}/elty/404.jpg`} alt="not found" fill />
          </div>
          <h1 className="text-xl md:text-3xl lg:text-4xl font-semibold text-neutral-darker text-center">
            Oops! Hai trovato una pagina <br className="block lg:hidden" /> che non esiste
          </h1>
          <p className="text-base md:text-xl lg:text-2xl font-normal text-neutral-lighter text-center">
            Non preoccuparti, torna alla home e ti
            <br />
            aiuteremo a trovare quello che cerchi
          </p>
          <NavLink href={'/'} variant="filled" color="secondary" aria-label="Torna alla home" label="Torna alla home" />
        </div>
      </div>
    </PageContainer>
  );
};

export default Page;
